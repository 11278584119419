<template>
  <div id="app">
    <!-- <Navbar/> -->
    <router-view/>
  </div>
</template>

<script>
// import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    // Navbar
  },
  data(){

  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
    font-family: 'Vollkorn', 'Times New Roman', serif;
    font-weight: 400;
}

.fullscreen-overlay {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
}
.content-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-hopkins {
  background-color: #222e2e;
  color: white;
}
</style>