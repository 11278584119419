import Vue from 'vue'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faUser, faKey, faChevronDown, faChevronUp, faQuestionCircle, faEye} from '@fortawesome/free-solid-svg-icons'
library.add(faUser, faKey, faChevronDown, faChevronUp, faQuestionCircle, faEye)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

Vue.config.productionTip = false

import router from '/src/router/index.js'

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
