import Vue from 'vue'
import VueRouter from 'vue-router'

// import Login from '/src/views/Login.vue'
import Browser from '/src/views/Browser.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Browser',
    component: Browser
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
