<template>
  <div id="browser" class="">
    <div v-for="(folder,index) in folders" :key="index" class="folder">

      <div class="folder-top bg-hopkins" @click="toggleFolder(index)">
        <div class="folder-title">{{ folder.title }}</div>
        <div class="folder-arrow">
          <font-awesome-icon v-show="folder.collapsed" icon="chevron-down"/>
          <font-awesome-icon v-show="!folder.collapsed" icon="chevron-up"/>
        </div>
      </div>

      <div class="folder-hidden" v-show="!folder.collapsed">
        <div class="folder-contents">
          <div v-for="(item,index) in folder.contents" :key="index" class="folder-item">
            <div class="item-title">{{item.title}}</div>
            <a :href="item.image" target="_blank">
              <div v-if="item.image" class="item-image">
                <img :src="item.image" alt="">
              </div>
              </a>
            <div v-if="item.video" class="item-video">
              <youtube :video-id="item.video"></youtube>
              <div class="loading">Loading...</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import letter from '/src/assets/letter.jpeg'
import lsc_newsletter from '/src/assets/lsc_newsletter.jpeg'
import transcript from '/src/assets/transcript.jpeg'

export default {
  name: 'Browser',
  data(){
    return {
      folders: [
        {
          title: 'Documents',
          collapsed: true,
          contents: [
            {
              title: 'lsc_newsletter.pdf',
              image: lsc_newsletter
            },
            {
              title: 'cease_and_desist.doc',
              image: letter
            },
            {
              title: 'transcript.png',
              image: transcript
            },
          ]
        },
        {
          title: 'Videos',
          collapsed: true,
          contents: [
            {
              title: 'Bit close to the bone? Bloody careless of Roy. No mention of LSC.',
              video: 'DCxKenb9YO8'
            }
          ]
        },

      ]
    }
  },
  methods: {
    toggleFolder(index){
      this.folders[index].collapsed = !this.folders[index].collapsed
    }
  },
  props: {
  
  }
}
</script>

<style scoped>
#browser {
  padding: 5%;
}
.folder {
  margin: 5px;
}
.folder-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 40px;
}
.folder-top:hover {
  filter: brightness(120%);
  cursor: pointer;
}
.folder-title {
  font-size: 2.5em;
}
.folder-contents {
  display: flex;
}
.folder-item {
  flex: 0 1 25%;
}

.item-image {
  width: 100%;
}
.item-image > img {
  width: 100%;
}
.item-video {
  border: 1px solid black;
  position: relative;
}
.item-video .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}


</style>
